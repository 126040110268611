import React, { useState } from "react"
import Layout from '../../components/layout'
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap'
import ReferralForm from './ReferralForm'
import Logo from "../../images/newLogo.png"



const Referral = (props) => {
    var showForm = false;
    const params = new URLSearchParams(props.location.search);
    if (params.get("openForm")){
        showForm = true;
    }
    const [modal, setModal] = useState(showForm);
    const toggle = () => setModal(!modal);

    return (
        <Layout>
            <section className="ref_bg">
                <Container>
                    <div className="email_bg">
                        <div className="ref_border" />
                        <div>
                            <h6> <strong>Trident Consulting</strong> is an award-winning Information Technology/Engineering staffing company
                                headquartered in San Ramon, CA. We provide IT Staffing and SoW-based solutions nationwide, with
                                our clients spanning Fortune 100 companies and innovative mid-market companies from all over the
                                US.
                    </h6>
                            <h6><strong> Our focus is on finding equal opportunities for diverse candidates in technology.</strong><br />
                                More specifically, we work for clients who similarly focus on providing equal opportunities to qualified
                                candidates.
                    </h6>
                            <h6>
                                As such, we actively encourage <strong> Women, African Americans, Latinos, Pacific Islanders and
                        Native American candidates</strong> to apply for our open jobs.
                    </h6>
                            <div className="ref_card">
                                <div className="ref_card_bg">
                                    <h4>Currently, we are looking for qualified candidates for the following positions:</h4>
                                    <h4>iOS Developer – SFO, CA</h4>
                                    <h4>Android Developer – SFO, CA</h4>
                                    <h4>Java Developer – Remote</h4>
                                    <h4>The client is a well-known, multinational company keen on enhancing diversity and inclusiveness in their work culture.</h4>
                                </div>
                            </div>
                            <hr />
                            <h6>If you don't fit the profile, but know someone who does, please reach out and give us a referral.</h6>
                            <div className="ref_card">
                                <div className="ref_card_bg">
                                    <h3>FOR EVERY SUCCESSFUL START BY <br /> A DIVERSE CANDIDATE YOU REFER <br /> YOU EARN $1,000!</h3>
                                </div>
                            </div>
                            {/* <a href="https://www.tridentconsultinginc.com/referral?openForm=true"><button onClick={toggle} className="ref_btn" >REFER NOW!</button></a> */}
                            <button onClick={toggle} className="ref_btn" >REFER NOW!</button>
                            <Modal isOpen={modal} toggle={toggle} className="form_modal">
                                <ModalBody >
                                    <ReferralForm title="REFER A DIVERSE CANDIATE &amp; EARN $1000 FOR EVERY SUCCESSFUL START!" close={() => toggle()} />
                                </ModalBody>
                            </Modal>
                            <h6>You could also share the Referral's Resume for any of the open jobs listed here with:<br />
                                <strong> <a href="mailto:info@tridentconsultinginc.com">info@tridentconsultinginc.com</a> OR call us at <a href="tel:707-342-5897">707-342-5897</a></strong></h6>
                            <hr />
                            <div className="ref_min_card">
                                <a href="https://www.tridentconsultinginc.com" className="ref_logo">
                                    <img src={Logo} width={100} alt="img" />
                                </a>

                                <h6><br />
                                    Trident  was  recently  ranked  #45  in  SF  Business  Times  fastest-growing  private  companies  in  California,<br/>
                                    #180  in  the  Inc. 5000  fastest-growing  private  companies  in  America,  and  won  the<br/>
                                    WRMSDC  (Western  Regional  Minority  Supplier Development  Council)  Cornerstone  Award  for  2021.
                                {/* Trident won the
                                    WRMSDC (Western Regional Minority Supplier Development Council) Cornerstone Award for 2021, and was recently ranked #45 in SF Business Times fastest-growing private companies in
                                    California, #180 in the Inc. 5000 fastest-growing private companies in America. */}
                                </h6>
                            </div>
                            <h6><strong>Your referral will help us continue to push the needle further forward in the DE&I landscape.</strong> </h6>
                        </div>

                        {/* <h4>Trident Consulting is an award-winning IT/engineering staffing company headquartered in San Ramon, CA. We were recently ranked #45 in SF Business Times fastest-growing private companies in California and ranked #180 in the Inc 5000 fastest-growing private companies in America. </h4>
                <h4>Trident provides Information Technology Staffing and SoW based solutions nationwide. Our clients span across Fortune 100 to innovative mid-market companies throughout the US.</h4>
                <h4>Our focus is on finding equal opportunities for diverse candidates in technology.</h4>
                <h4>More specifically our clients are focused on providing equal opportunity for qualified candidates. We highly encourage Women, African Americans, Latinos, Pacific Islanders and Native American candidates to apply for our open jobs.</h4>
                <h4>If you know of any respective candidates, please reach out as we are striving to move the needle forward in the DE&I landscape.</h4>
                <h3 className="email_link">
                <a href="#" onClick={toggle}>REFER A DIVERSE CANDIATE & EARN $1000 FOR EVERY SUCCESFUL START! </a>
                </h3>
                 <Modal isOpen={modal} toggle={toggle}  className="form_modal">
                    <ModalBody >
                        <ReferralForm title="REFER A DIVERSE CANDIATE &amp; EARN $1000 FOR EVERY SUCCESFUL START!" close={() => toggle()} />
                    </ModalBody>
                </Modal>
                <h4>Refer by sharing the resume for any of the below open jobs - sam@tridentconsultinginc.com </h4>
                <div className="email_center">
                <h4>(OR)</h4>
                <h4> Reach us at 707-342-5897</h4>
                </div>
                <h3>Actively hiring for the below:</h3>
                <ul>
                    <li>iOS Developer – SFO, CA</li>
                    <li>Android Developer – SFO, CA</li>
                    <li>Java Developer – Remote</li>
                </ul>
                <div className="link">
                    <a href="https://www.tridentconsultinginc.com/">www.tridentconsultinginc.com | www.tridentjobs.us</a>
                </div> */}

                    </div>
                </Container>
            </section>
        </Layout>
    )
}

export default Referral
